import React, { useState } from 'react';
import { Rnd } from 'react-rnd';
import { PiArrowFatLinesDownFill } from 'react-icons/pi';

const DynamicRuler = ({ orientation, size, onLinePositionChange }) => {
  const [dragPosition, setDragPosition] = useState(48);
  const [dragPosition2, setDragPosition2] = useState(750);

  const rulerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: orientation === 'portrait' ? size.width : size.height,
    margin: '6px 0',
    borderBottom: '1px solid #ccc',
    position: 'relative',
  };

  const renderTicks = () => {
    const majorTickHeight = 15;
    const minorTickHeight = 8;
    const totalMarks = 10;
    const subMarks = 5;
    const tickWidth = 100 / (totalMarks * subMarks);

    let ticks = [];

    for (let i = 0; i <= totalMarks * subMarks; i++) {
      const isMajorTick = i % subMarks === 0;

      ticks.push(
        <div key={i} style={{
          flex: `${tickWidth}%`,
          textAlign: 'center',
          position: 'relative',
          borderLeft: isMajorTick ? '2px solid #000' : '1px solid #000',
        }}>
          {isMajorTick && (
            <span style={{
              position: 'absolute',
              bottom: '-20px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}>
              {i / subMarks}
            </span>
          )}

          {!isMajorTick && (
            <span style={{
              position: 'absolute',
              bottom: '-5px',
              left: '50%',
              transform: 'translateX(-50%)',
              fontSize: '5px',
            }}>
              |
            </span>
          )}
        </div>
      );
    }
    return ticks;
  };

  const handleDrag = (e, data) => {
    e.stopPropagation();
    setDragPosition(data.x);
    onLinePositionChange(data.x);
  };

  const handleDrag2 = (e, data) => {
    e.stopPropagation();
    setDragPosition2(data.x);
    onLinePositionChange(data.x);
  };

  return (
    <div className="dynamic-ruler mt-3" style={{ position: 'relative', ...rulerStyle }}>
      {renderTicks()}

      <Rnd
        default={{ x: dragPosition, y: 0, width: 25, height: 30 }}
        bounds="parent"
        enableResizing={false}
        onDrag={(e, data) => handleDrag(e, data)}
        style={{ cursor: 'pointer', position: 'absolute', top: '-10px', left: 0 }}
      >
        <PiArrowFatLinesDownFill size={22} color="#4285f4ed" />
      </Rnd>
      <Rnd
        default={{ x: dragPosition2, y: 0, width: 25, height: 30 }}
        bounds="parent"
        enableResizing={false}
        onDrag={(e, data) => handleDrag2(e, data)}
        style={{ cursor: 'pointer', position: 'absolute', top: '-10px', right: 0 }}
      >
        <PiArrowFatLinesDownFill size={22} color="#4285f4ed" />
      </Rnd>
    </div>
  );
};

export default DynamicRuler;
