import React, { useState } from 'react';
import { BsScissors } from 'react-icons/bs';
import { RiAlignLeft } from 'react-icons/ri';
import { LiaPasteSolid } from 'react-icons/lia';
import InsertShape from '../InsertDesign/InsertShape';
import InsertEmoji from '../InsertDesign/InsertEmoji';
import InsertPictures from '../InsertDesign/InsertPictures';
import LayoutSizeDesign from '../LayoutDesigns/LayoutSizeDesign';
import TableProperties from '../InsertDesign/Table/TableProperties';
import LayoutMarginDesign from '../LayoutDesigns/LayoutMarginDesign';
import LayoutColumnDesign from '../LayoutDesigns/LayoutColumnDesign';
import InsertTableDesign from '../InsertDesign/Table/InsertTableDesign';
import { GrCopy, GrTextAlignCenter, GrUnderline } from 'react-icons/gr';
import { PiPaintBrushHouseholdFill, PiTextItalic } from 'react-icons/pi';
import LayoutOrientationDesign from '../LayoutDesigns/LayoutOrientationDesign';
import { MdFormatAlignJustify, MdOutlineFormatAlignRight } from 'react-icons/md';

const Toolbar = ({ setOrientation, handleMarginChange, onSizeChange, onColumnChange,
    onShapeInsert, onInsertTable, setActiveTab, activeTab, tableProperties, updateTableProperties, addRow, addColumn, deleteRow, deleteColumn,
    onToggleHeader, onToggleFooter, onPrint, handleReportButtonClick, onConvertToPDF, onEmojiInsert, handleImageUpload, handleCellChange, selectedCell }) => {

    const [fontSize, setFontSize] = useState(11);
    const [show, setShow] = useState(false);
    const [bgColor, setBgColor] = useState('#ffffff');
    const [fontColor, setFontColor] = useState('#000000');
    const [fontFamily, setFontFamily] = useState('Calibri (Body)');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCommand = (command, value = null) => {
        document.execCommand(command, false, value);
    };

    const applyTextFormat = (format) => {
        if (format === 'normal') {
            document.execCommand('formatBlock', false, 'p');
        } else {
            document.execCommand('formatBlock', false, format);
        }
    };

    const handleFontSizeChange = (size) => {
        document.execCommand('fontSize', false, '7');
        const elements = document.getElementsByTagName('font');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].size === '7') {
                elements[i].removeAttribute('size');
                elements[i].style.fontSize = `${size}px`;
            }
        }
    };

    const handleHyperlink = () => {
        let url = prompt("Enter the URL");

        if (url) {
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                url = `https://${url}`;
            }
            document.execCommand('createLink', false, url);

            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const anchorNode = selection.anchorNode;
                const parentElement = anchorNode?.parentElement;

                if (parentElement && parentElement.tagName === 'A') {
                    parentElement.setAttribute('target', '_blank');
                    parentElement.style.cursor = 'pointer';
                    parentElement.addEventListener('click', (e) => {
                        window.open(parentElement.href, '_blank');
                    });
                }
            }
        }
    };

    const renderHomeToolbar = () => (
        <div className="toolbar d-flex bg-#f9f9f9 p-2" style={{ border: "1px solid #ccc" }}>
            <div className="d-flex gap-1 CP toolbar_button">
                <button title='paste' onClick={() => handleCommand('paste')}><LiaPasteSolid /></button>
                <button title='cut' onClick={() => handleCommand('cut')}><BsScissors /></button>
                <button title='copy' onClick={() => handleCommand('copy')}><GrCopy /></button>
                <button title='format painter'><PiPaintBrushHouseholdFill /></button>
            </div>

            <div className="d-flex gap-1 CP toolbar_button">
                <select className="toolbar-select" value={fontFamily} onChange={(e) => handleCommand('fontName', e.target.value)}>
                    <option>Calibri (Body)</option>
                    <option>Arial</option>
                    <option>Times New Roman</option>
                    <option>Verdana</option>
                    <option>Georgia</option>
                    <option>Roboto</option>
                </select>

                <select
                    value={fontSize}
                    onChange={(e) => {
                        setFontSize(e.target.value);
                        handleFontSizeChange(e.target.value);
                    }}
                >
                    {[...Array(45)].map((_, index) => {
                        const size = index + 6;
                        return <option key={size} value={size}>{size}</option>;
                    })}
                </select>

                <button title='Bold' style={{ fontWeight: "bold" }} onClick={() => handleCommand('bold')}>B</button>
                <button title='Italic' onClick={() => handleCommand('italic')}><PiTextItalic /></button>
                <button title='Underline' onClick={() => handleCommand('underline')} style={{ fontSize: 16 }}><GrUnderline /></button>

                <input
                    type="color"
                    value={fontColor}
                    onChange={(e) => handleCommand('foreColor', e.target.value)}
                    title="Font Color"
                    className="toolbar-input"
                />
                <input
                    type="color"
                    value={bgColor}
                    onChange={(e) => {
                        handleCommand('hiliteColor', e.target.value)
                    }}
                    title="Background Color"
                    className="toolbar-input"
                />
            </div>

            <div className="d-flex gap-1 CP toolbar_button">
                <button title='Align left' onClick={() => handleCommand('justifyLeft')}><RiAlignLeft /></button>
                <button title='Align center' onClick={() => handleCommand('justifyCenter')}><GrTextAlignCenter /></button>
                <button title='Align right' onClick={() => handleCommand('justifyRight')}><MdOutlineFormatAlignRight /></button>
                <button title='Justify' onClick={() => handleCommand('justifyFull')}><MdFormatAlignJustify /></button>
                <button title='Header' onClick={onToggleHeader} >Header</button>
                <button title='Footer' onClick={onToggleFooter} >Footer</button>
                <button title='Print' onClick={onPrint} >Print</button>
                <button title='Convert' onClick={onConvertToPDF} >Convert To PDF</button>
                <button title='Hyperlink' onClick={handleHyperlink}>Hyperlink</button>
                <button title='Table Properties' onClick={handleShow}>Table Properties</button>
                <button title='Reports' onClick={handleReportButtonClick}>Reports</button>
                <select className="toolbar-select" onChange={(e) => applyTextFormat(e.target.value)}>
                    <option value="normal">Normal</option>
                    <option value="h1">Heading 1</option>
                    <option value="h2">Heading 2</option>
                    <option value="h3">Heading 3</option>
                    <option value="h4">Heading 4</option>
                    <option value="h5">Heading 5</option>
                    <option value="h6">Heading 6</option>
                </select>
            </div>
        </div>
    );

    const renderInsertToolbar = () => (
        <div className="toolbar">
            <div className="d-flex gap-1 CP toolbar_button">
                <button><InsertPictures handleImageUpload={handleImageUpload} /></button>
                <button><InsertTableDesign onInsertTable={onInsertTable} /></button>
                <button>Chart</button>
                <button><InsertShape onShapeInsert={onShapeInsert} /></button>
                <button><InsertEmoji onEmojiInsert={onEmojiInsert} /></button>
            </div>
        </div>
    );

    const renderLayoutToolbar = () => (
        <div className="toolbar">
            <div className="d-flex gap-1 CP toolbar_button">
                <button><LayoutMarginDesign handleMarginChange={handleMarginChange} /></button>
                <button><LayoutOrientationDesign setOrientation={setOrientation}
                /></button>
                <button><LayoutSizeDesign onSelectSize={onSizeChange} /></button>
                <button><LayoutColumnDesign onSelectColumn={onColumnChange} /></button>
            </div>

        </div>
    );

    const renderToolbar = () => {
        switch (activeTab) {
            case 'Home':
                return renderHomeToolbar();
            case 'Insert':
                return renderInsertToolbar();
            case 'Layout':
                return renderLayoutToolbar();
            default:
                return renderHomeToolbar();
        }
    };

    return (
        <div className='position-sticky top-0 z-1'>
            <div className="d-flex m-0 border-bottom gap-3 bg-white">
                <button
                    className={`tab CP ${activeTab === 'Home' ? 'active' : ''}`}
                    onClick={() => setActiveTab('Home')}>
                    Home
                </button>
                <button
                    className={`tab CP ${activeTab === 'Insert' ? 'active' : ''}`}
                    onClick={() => setActiveTab('Insert')}>
                    Insert
                </button>
                <button
                    className={`tab CP ${activeTab === 'Layout' ? 'active' : ''}`}
                    onClick={() => setActiveTab('Layout')}>
                    Layout
                </button>
            </div>

            <div>
                {renderToolbar()}
            </div>
            <TableProperties show={show} handleClose={handleClose}
                tableProperties={tableProperties}
                updateTableProperties={updateTableProperties}
                addRow={addRow}
                addColumn={addColumn}
                deleteRow={deleteRow}
                deleteColumn={deleteColumn}
                handleCellChange={handleCellChange}
                selectedCell={selectedCell}
            />
        </div>
    );
};

export default Toolbar;
