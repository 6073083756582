import React, { useState, useRef, useEffect } from 'react';
import InsertCustomTable from './InsertCustomTable';

const InsertTableDesign = ({ onInsertTable }) => {
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCol, setHoveredCol] = useState(null);
  const [tableDimensions, setTableDimensions] = useState({ rows: 0, columns: 0 });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dropdownRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleTableSelection = (rows, columns) => {
    setTableDimensions({ rows, columns });
    onInsertTable({ rows, columns });
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef}>
      <div onClick={toggleOpen} className="position-relative" title='Insert Table'>
        Table
      </div>
      {isOpen && (
        <div className="table-grid-dropdown position-absolute" style={{ width: 450 }}>
          <div className="table-grid-selection">
            {Array(15).fill(0).map((_, rowIndex) => (
              <div key={rowIndex} className="d-flex">
                {Array(15).fill(0).map((_, colIndex) => (
                  <div
                    key={colIndex}
                    onClick={() => handleTableSelection(rowIndex + 1, colIndex + 1)}
                    onMouseEnter={() => {
                      setHoveredRow(rowIndex);
                      setHoveredCol(colIndex);
                    }}
                    onMouseLeave={() => {
                      setHoveredRow(null);
                      setHoveredCol(null);
                    }}
                    className="table-grid-cell"
                    style={{
                      width: 50,
                      height: 30,
                      border: '1px solid #ccc',
                      cursor: 'pointer',
                      backgroundColor:
                        // rowIndex < tableDimensions.rows && colIndex < tableDimensions.columns
                        //   ? '#ddd'
                        //   : 
                        rowIndex <= hoveredRow && colIndex <= hoveredCol
                          ? '#ddd'
                          : '#fff',
                    }}
                  />
                ))}
              </div>
            ))}
          </div>
          <div className='toolbar_button py-2'>
            <button onClick={handleShow}>
              Insert Custom Table
            </button>
          </div>
        </div>
      )}
      <InsertCustomTable handleClose={handleClose} show={show} handleTableSelection={handleTableSelection} />
    </div>
  );
};

export default InsertTableDesign;
