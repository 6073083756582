import React, { useState } from "react";

const JasonAppend = () => {
  const [mergedData, setMergedData] = useState([]);

  // First JSON: id, name, age
  const data1 = [
    { id: 1, name: "John", age: 25 },
    { id: 2, name: "Sarah", age: 30 },
    { id: 3, name: "Kate", age: 56 },
  ];

  // Second JSON: id, name, dob
  const data2 = [
    { id: 1, name: "John", dob: "1995-01-01" },
    { id: 1, name: "Satish", dob: "2000-01-01" },
    { id: 2, name: "Karan Sam", dob: "1990-05-12" },
    { id: 3, name: "Roop", dob: "2005/05/12" },
    { id: 4, name: "Tom", dob: "1988-09-21"},
  ];

  // Function to merge data
  const mergeData = () => {
    let merged = [];

    // Merge entries from data1
    data1.forEach((item1) => {
      merged.push({
        id: item1.id,
        name: item1.name || "-",
        age: item1.age !== null ? item1.age : "-",
        dob: "-", // Placeholder for DOB from data1
      });
    });

    // Merge entries from data2
    data2.forEach((item2) => {
      merged.push({
        id: item2.id,
        name: item2.name || "-",
        age: "-", // Placeholder for age from data2
        dob: item2.dob !== null && item2.dob !== undefined ? item2.dob : "-",
      });
    });

    // Sort the merged data by id so that rows with the same id are grouped together
    merged.sort((a, b) => a.id - b.id);

    // Set merged data to state
    setMergedData(merged);
    console.log("Merged Data:", merged);
  };

  return (
    <div>
      <h1>Append JSON Data</h1>
      <button onClick={mergeData}>Append</button>

      {/* Render Table if data is available */}
      {mergedData.length > 0 && (
        <table border="1" style={{ marginTop: "20px", width: "100%" }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Age</th>
              <th>Date of Birth (DOB)</th>
            </tr>
          </thead>
          <tbody>
            {mergedData.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.age}</td>
                <td>{item.dob}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default JasonAppend;
