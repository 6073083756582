import React, { useEffect, useRef, useState } from 'react';
import { SlPicture } from 'react-icons/sl';

const InsertPictures = ({handleImageUpload }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <div ref={dropdownRef}>
            <div onClick={toggleOpen} className="position-relative" title='Insert Image'>
                Pictures
                {/* <SlPicture /> */}
            </div>
            {isOpen && (
                <div className="margin-dropdown position-absolute" style={{ width: 250 }}>
                    <div className="margin-option border-bottom d-flex gap-3 CP">
                        <label htmlFor="image-upload" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <div style={{ fontSize: 30, marginTop: -10, marginRight: 5 }}><SlPicture /></div>
                            <div style={{ marginTop: 0, fontSize: 13, fontWeight: 500 }}>Select From This Device</div>
                        </label>
                        <input
                            id="image-upload"
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleImageUpload}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default InsertPictures;
