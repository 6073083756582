import React, { useState, useRef, useEffect } from 'react';

const ExcelSpreadsheet = ({ formatting }) => {
  const initialMouseX = useRef(0);
  const isResizing = useRef(false);
  const initialColumnWidth = useRef(0);
  const resizingColumnIndex = useRef(null);
  const [mergedCells, setMergedCells] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedCells, setSelectedCells] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [columnWidths, setColumnWidths] = useState(Array(10).fill(100));
  const [grid, setGrid] = useState(Array(32).fill().map(() => Array(20).fill('')));

  const columnLabels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("").slice(0, grid[0].length);
  const rowLabels = Array.from({ length: grid.length }, (_, i) => i + 1);

  const getCellStyle = (rowIndex, colIndex) => {
    const isSelectedCell = selectedCell && selectedCell.row === rowIndex && selectedCell.col === colIndex;
    const isSelectedRow = selectedRow === rowIndex;
    const isSelectedColumn = selectedColumn === colIndex;
    const isInSelectedCells = selectedCells.some(cell => cell.row === rowIndex && cell.col === colIndex);
    const isInMergedCells = mergedCells.some(merge => (
      rowIndex >= merge.startRow && rowIndex <= merge.endRow &&
      colIndex >= merge.startCol && colIndex <= merge.endCol
    ));

    const baseStyle = isSelectedCell || isSelectedRow || isSelectedColumn || isInSelectedCells
      ? { ...formatting, backgroundColor: '#a4a7b3', color: 'white' }
      : {};

    if (isInMergedCells) {
      const mergeInfo = mergedCells.find(merge =>
        rowIndex >= merge.startRow && rowIndex <= merge.endRow &&
        colIndex >= merge.startCol && colIndex <= merge.endCol
      );

      return {
        ...baseStyle,
        borderTop: rowIndex === mergeInfo.startRow ? '1px solid black' : 'none',
        borderBottom: rowIndex === mergeInfo.endRow ? '1px solid black' : 'none',
        borderLeft: colIndex === mergeInfo.startCol ? '1px solid black' : 'none',
        borderRight: colIndex === mergeInfo.endCol ? '1px solid black' : 'none',
      };
    }

    return baseStyle;
  };

  const getHeaderStyle = (index, isRow = false) => {
    const isSelected = selectedCell && ((isRow && selectedCell.row === index) || (!isRow && selectedCell.col === index));
    return isSelected ? { backgroundColor: '#a4a7b3', color: 'white' } : {};
  };

  const handleCellChange = (e, rowIndex, colIndex) => {
    const updatedGrid = grid.map((row, rIdx) =>
      row.map((cell, cIdx) => (rIdx === rowIndex && cIdx === colIndex ? e.target.value : cell))
    );
    setGrid(updatedGrid);
  };

  const handleContextMenuAction = (action) => {
    if (!selectedCell) return;
    const { row, col } = selectedCell;
    const cellContent = grid[row][col];

    if (action === 'cut' || action === 'copy') navigator.clipboard.writeText(cellContent);
    if (action === 'cut') handleCellChange({ target: { value: '' } }, row, col);
    if (action === 'paste') navigator.clipboard.readText().then((text) =>
      handleCellChange({ target: { value: text } }, row, col)
    );
    if (action === 'merge') mergeCells();
    setContextMenu(null);
  };

  const mergeCells = () => {
    if (selectedCells.length < 2) return;

    const minRow = Math.min(...selectedCells.map(cell => cell.row));
    const maxRow = Math.max(...selectedCells.map(cell => cell.row));
    const minCol = Math.min(...selectedCells.map(cell => cell.col));
    const maxCol = Math.max(...selectedCells.map(cell => cell.col));

    const mergedContent = selectedCells.map(({ row, col }) => grid[row][col]).join(' ');

    const updatedGrid = grid.map((row, rowIndex) =>
      row.map((cell, colIndex) => {
        if (
          rowIndex >= minRow && rowIndex <= maxRow &&
          colIndex >= minCol && colIndex <= maxCol
        ) {
          return rowIndex === minRow && colIndex === minCol ? mergedContent : '';
        }
        return cell;
      })
    );

    setGrid(updatedGrid);
    setMergedCells([...mergedCells, { startRow: minRow, endRow: maxRow, startCol: minCol, endCol: maxCol }]);
    setSelectedCells([{ row: minRow, col: minCol }]);
  };
  const handleMouseDown = (e, colIndex) => {
    isResizing.current = true;
    initialMouseX.current = e.clientX;
    initialColumnWidth.current = columnWidths[colIndex];
    resizingColumnIndex.current = colIndex;
  };

  const handleMouseMove = (e) => {
    if (isResizing.current) {
      const deltaX = e.clientX - initialMouseX.current;
      setColumnWidths((widths) => {
        const newWidths = [...widths];
        newWidths[resizingColumnIndex.current] = Math.max(50, initialColumnWidth.current + deltaX);
        return newWidths;
      });
    }
  };

  const handleMouseUp = () => (isResizing.current = false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (contextMenu && !e.target.closest('.context-menu')) setContextMenu(null);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [contextMenu]);

  return (
    <div className="spreadsheet" onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
      <table>
        <thead>
          <tr>
            <th></th>
            {columnLabels.map((label, index) => (
              <th
                key={index}
                style={{ width: `${columnWidths[index]}px`, ...getHeaderStyle(index) }}
                onClick={() => {
                  setSelectedRow(null);
                  setSelectedColumn(index);
                }}
              >
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {grid.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <th
                style={getHeaderStyle(rowIndex, true)}
                onClick={() => {
                  setSelectedColumn(null);
                  setSelectedRow(rowIndex);
                }}
              >
                {rowLabels[rowIndex]}
              </th>
              {row.map((cell, colIndex) => (
                <td
                  key={colIndex}
                  // onClick={() => setSelectedCell({ row: rowIndex, col: colIndex })}
                  onClick={() => {
                    const cellExists = selectedCells.some(cell => cell.row === rowIndex && cell.col === colIndex);
                    setSelectedCell({ row: rowIndex, col: colIndex });
                    setSelectedCells(prev => cellExists ? prev : [...prev, { row: rowIndex, col: colIndex }]);
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    setSelectedCell({ row: rowIndex, col: colIndex });
                    setContextMenu({ x: e.clientX, y: e.clientY });
                  }}
                  style={{ ...getCellStyle(rowIndex, colIndex), width: `${columnWidths[colIndex]}px`, position: 'relative' }}
                >
                  <input
                    type="text"
                    value={grid[rowIndex][colIndex]}
                    onChange={(e) => handleCellChange(e, rowIndex, colIndex)}
                    style={{ width: '100%', height: '100%' }}
                  />
                  {colIndex < row.length - 1 && (
                    <div
                      onMouseDown={(e) => handleMouseDown(e, colIndex)}
                      style={{ position: 'absolute', right: 0, top: 0, bottom: 0, width: '5px', cursor: 'col-resize', backgroundColor: 'transparent' }}
                    />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {contextMenu && (
        <div className="context-menu" style={{ top: contextMenu.y, left: contextMenu.x, position: 'absolute', zIndex: 1000 }}>
          <div onClick={() => handleContextMenuAction('cut')}>Cut</div>
          <div onClick={() => handleContextMenuAction('copy')}>Copy</div>
          <div onClick={() => handleContextMenuAction('paste')}>Paste</div>
          <div onClick={() => handleContextMenuAction('merge')}>Merge</div>
        </div>
      )}
    </div>
  );
};

export default ExcelSpreadsheet;
