import React, { useEffect, useRef, useState } from 'react'
import { IoTabletPortraitOutline } from 'react-icons/io5';

const LayoutSizeDesign = ({ onSelectSize }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const sizes = [
        { label: 'Letter', dimensions: { width: '8.5in', height: '11in' }, display: '8.5" X 11"' },
        { label: 'Tabloid', dimensions: { width: '11in', height: '17in' }, display: '11" X 17"' },
        { label: 'Legal', dimensions: { width: '8.5in', height: '14in' }, display: '8.5" X 14"' },
        { label: 'Statement', dimensions: { width: '5.5in', height: '8.5in' }, display: '5.5" X 8.5"' },
        { label: 'Executive', dimensions: { width: '7.25in', height: '10.5in' }, display: '7.25" X 10.5"' },
        { label: 'A3', dimensions: { width: '11.69in', height: '16.54in' }, display: '11.69" X 16.54"' },
        { label: 'A4', dimensions: { width: '8.27in', height: '11.69in' }, display: '8.27" X 11.69"' },
        { label: 'B4 (JIS)', dimensions: { width: '10.12in', height: '14.43in' }, display: '10.12" X 14.33"' },
        { label: 'B5 (JIS', dimensions: { width: '7.17in', height: '10.12in' }, display: '7.17" X 10.12"' }
    ];

    return (
        <>
            <div ref={dropdownRef}>
                <div onClick={toggleOpen} className="position-relative">
                    Size
                </div>
                {isOpen && (
                    <div className="margin-dropdown position-absolute" style={{ width: 150 }}>
                        {sizes.map((size, index) => (
                            <div key={index} className='d-flex gap-3 border-bottom mb-2' onClick={() => {
                                setIsOpen(false);
                                onSelectSize(size.dimensions)
                            }}
                            >
                                <div className='mt-1'>
                                    <IoTabletPortraitOutline style={{ fontSize: 30 }} />
                                </div>
                                <div>
                                    <h6 className='m-0' style={{ fontSize: 13 }}>{size.label}</h6>
                                    <span style={{ fontSize: 12 }}>{size.display}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default LayoutSizeDesign