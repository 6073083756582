import React, { useEffect, useRef, useState } from 'react'
import { PiTextColumns } from 'react-icons/pi';
import { MdFormatAlignCenter } from 'react-icons/md';
import { BsListColumns, BsListColumnsReverse } from 'react-icons/bs';

const LayoutColumnDesign = ({ onSelectColumn }) => {

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const handleColumnSelect = (columns) => {
        onSelectColumn(columns);
        setIsOpen(false);
    };

    return (
        <>
            <div ref={dropdownRef}>
                <div onClick={toggleOpen} className="position-relative">
                    Columns
                </div>
                {isOpen && (
                    <div className="margin-dropdown position-absolute" style={{ width: 150 }}>
                        <div
                            className="margin-option border-bottom d-flex gap-2 CP"
                            onClick={() => {
                                setIsOpen(false);
                                handleColumnSelect(1)
                            }}
                        >
                            <div style={{ fontSize: 20, marginTop: -10 }} className='p-1'><MdFormatAlignCenter /></div>
                            <div style={{ marginTop: 1 }}>One</div>
                        </div>
                        <div
                            className="margin-option border-bottom d-flex gap-2 CP"
                            onClick={() => {
                                setIsOpen(false);
                                handleColumnSelect(2)
                            }}
                        >
                            <div style={{ fontSize: 20, marginTop: -10 }} className='p-1'><PiTextColumns /></div>
                            <div style={{ marginTop: 1 }}>Two</div>
                        </div>
                        <div
                            className="margin-option border-bottom d-flex gap-2 CP"
                            onClick={() => {
                                setIsOpen(false);
                                handleColumnSelect(3)
                            }}
                        >
                            <div style={{ fontSize: 20, marginTop: -10 }} className='p-1'><MdFormatAlignCenter /></div>
                            <div style={{ marginTop: 1 }}>Three</div>
                        </div>
                        <div
                            className="margin-option border-bottom d-flex gap-2 CP"
                            onClick={() => {
                                setIsOpen(false);
                                handleColumnSelect('left')
                            }}
                        >
                            <div style={{ fontSize: 17, marginTop: -5, marginLeft: 5 }} className='p-1'><BsListColumnsReverse /></div>
                            <div style={{ marginTop: 1 }}>Left</div>
                        </div>
                        {/* <div
                            className="margin-option border-bottom d-flex gap-2 CP"
                            onClick={() => {
                                setIsOpen(false);
                                handleColumnSelect('right')
                            }}
                        >
                            <div style={{ fontSize: 17, marginTop: -5, marginLeft: 5 }} className='p-1'><BsListColumns /></div>
                            <div style={{ marginTop: 1 }}>Right</div>
                        </div> */}
                    </div>
                )}
            </div>
        </>
    )
}

export default LayoutColumnDesign