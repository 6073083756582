import React, { useState } from 'react';
import Toolbar from './Toolbar';
import ExcelSpreadsheet from './ExcelSpreadsheet';

const ExcelParentFile = () => {
    const [formatting, setFormatting] = useState({
        bold: false,
        italic: false,
        underline: false,
        fontSize: '14px',
        color: '#000000',
        backgroundColor: '#ffffff',
        fontFamily: 'Arial',
    });

    const applyFormatting = (type, value) => {
        switch (type) {
            case 'bold':
                setFormatting((prev) => ({ ...prev, fontWeight: prev.fontWeight === 'bold' ? 'normal' : 'bold' }));
                break;
            case 'italic':
                setFormatting((prev) => ({ ...prev, fontStyle: prev.fontStyle === 'italic' ? 'normal' : 'italic' }));
                break;
            case 'underline':
                setFormatting((prev) => ({ ...prev, textDecoration: prev.textDecoration === 'underline' ? 'none' : 'underline' }));
                break;
            case 'fontSize':
                setFormatting((prev) => ({ ...prev, fontSize: value }));
                break;
            case 'color':
                setFormatting((prev) => ({ ...prev, color: value }));
                break;
            case 'backgroundColor':
                setFormatting((prev) => ({ ...prev, backgroundColor: value }));
                break;
            case 'fontFamily':
                setFormatting((prev) => ({ ...prev, fontFamily: value }));
                break;
            default:
                break;
        }
    };

    return (
        <div className="excel-app">
            <Toolbar applyFormatting={applyFormatting} />
            <ExcelSpreadsheet formatting={formatting} />
        </div>
    );
};

export default ExcelParentFile;
