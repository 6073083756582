import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { GoTable } from 'react-icons/go';

const LayoutMarginDesign = ({ handleMarginChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const marginOptions = [
        { name: 'Normal', margins: { top: '1in', bottom: '1in', left: '1in', right: '1in' } },
        { name: 'Narrow', margins: { top: '0.25in', bottom: '0.25in', left: '0.5in', right: '0.5in' } },
        { name: 'Moderate', margins: { top: '1in', bottom: '1in', left: '0.75in', right: '0.75in' } },
        { name: 'Wide', margins: { top: '1in', bottom: '1in', left: '2in', right: '2in' } },
        { name: 'Mirrored', margins: { top: '1in', bottom: '1in', left: '1.25in', right: '1in' } },
        { name: 'Galax 2024 Default', margins: { top: '1in', bottom: '1in', left: '1.25in', right: '1.25in' } },
    ];

    const handleMarginClick = (margins) => {
        handleMarginChange(margins);
        setIsOpen(false);
    };

    return (
        <>
            <div ref={dropdownRef}>
                <div onClick={toggleOpen} className="position-relative">
                    Margins
                </div>
                {isOpen && (
                    <div className="margin-dropdown position-absolute" style={{ width: 250 }}>
                        {marginOptions?.map((option) => (
                            <div
                                key={option.name}
                                className="margin-option border-bottom d-flex gap-1 CP"
                                onClick={() => {
                                    setIsOpen(false);
                                    handleMarginClick(option.margins);
                                }}
                            >
                                <div style={{ fontSize: 45 }}><GoTable /></div>
                                <div className="w-100">
                                    <span style={{ fontWeight: 500, fontSize: 13 }}>{option.name}</span>
                                    <Row style={{ fontSize: 12 }}>
                                        <Col md={6}>Top: {option.margins.top}</Col>
                                        <Col md={6}>Bottom: {option.margins.bottom}</Col>
                                        <Col md={6}>Left: {option.margins.left}</Col>
                                        <Col md={6}>Right: {option.margins.right}</Col>
                                    </Row>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default LayoutMarginDesign;
