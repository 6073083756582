import React, { useState } from 'react';
import { GrUnderline } from 'react-icons/gr';
import { PiTextItalic } from 'react-icons/pi';

const Toolbar = ({ applyFormatting }) => {
    const [fontSize, setFontSize] = useState('14px');
    const [fontColor, setFontColor] = useState('#000000');
    const [bgColor, setBgColor] = useState('#ffffff');
    const [fontFamily, setFontFamily] = useState('Arial');

    return (
        <div className="toolbar d-flex bg-#f9f9f9 p-1 toolbar_button" style={{ border: "1px solid #ccc" }}>
            <button onClick={() => applyFormatting('bold')} title='Bold'>B</button>
            <button onClick={() => applyFormatting('italic')} title='Italic'><PiTextItalic /></button>
            <button onClick={() => applyFormatting('underline')} title='Underline' style={{ fontSize: 16 }}><GrUnderline /></button>
            <select
                onChange={(e) => {
                    setFontSize(e.target.value);
                    applyFormatting('fontSize', e.target.value);
                }}
                value={fontSize}
                className="toolbar-select"
            >
                {Array.from({ length: 20 }, (_, i) => {
                    const size = 8 + i * 2;
                    return (
                        <option key={size} value={`${size}px`}>
                            {size}
                        </option>
                    );
                })}
            </select>
            <input
                type="color"
                title='Font Color'
                className="toolbar-select"
                value={fontColor}
                onChange={(e) => {
                    setFontColor(e.target.value);
                    applyFormatting('color', e.target.value);
                }}
            />

            <input
                type="color"
                title='Background Color'
                className="toolbar-select"
                value={bgColor}
                onChange={(e) => {
                    setBgColor(e.target.value);
                    applyFormatting('backgroundColor', e.target.value);
                }}
            />

            <select
                onChange={(e) => {
                    setFontFamily(e.target.value);
                    applyFormatting('fontFamily', e.target.value);
                }}
                value={fontFamily}
                className="toolbar-select"
            >
                <option value="Arial">Arial</option>
                <option value="Times New Roman">Times New Roman</option>
                <option value="Courier New">Courier New</option>
                <option value="Verdana">Verdana</option>
            </select>
        </div>
    );
};

export default Toolbar;
