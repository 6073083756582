import React from 'react';
import { Form, Offcanvas } from 'react-bootstrap';

const TableProperties = ({ show, handleClose, tableProperties,
    updateTableProperties, addRow, addColumn, deleteRow, deleteColumn, handleCellChange, selectedCell }) => {

    const handleInputChange = (property, value) => {
        updateTableProperties({
            ...tableProperties,
            [property]: value,
        });
    };

    const handleCellBackgroundColorChange = (value) => {
        if (selectedCell) {
            handleCellChange(selectedCell.rowIndex, selectedCell.colIndex, 'backgroundColor', value);
        }
    };

    return (
        <div>
            <Offcanvas show={show} onHide={handleClose} placement="end" style={{ width: '350px' }}>
                <Offcanvas.Header>
                    <Offcanvas.Title>Table Properties</Offcanvas.Title>
                    <div onClick={handleClose} className="close_modal CP" title="Close" style={{ cursor: 'pointer' }}>
                        X
                    </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="toolbar_button py-2 d-flex gap-4 mb-2">
                        <button onClick={addRow}>Add Row</button>
                        <button onClick={addColumn}>Add Column</button>
                        <button onClick={deleteRow}>Delete Row</button>
                    </div>
                    <button className="w-100 mb-2" onClick={deleteColumn}>Delete Column</button>

                    <Form.Group className="mb-2">
                        <Form.Label style={{ fontSize: 15 }}>Column Width</Form.Label>
                        <Form.Control
                            type="number"
                            value={tableProperties.columnWidth}
                            onChange={(e) => handleInputChange('columnWidth', e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label style={{ fontSize: 15 }}>Minimum Row Height</Form.Label>
                        <Form.Control
                            type="number"
                            value={tableProperties.minRowHeight}
                            onChange={(e) => handleInputChange('minRowHeight', e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-2" style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Check
                            type="checkbox"
                            checked={tableProperties.pinHeaderRows > 0}
                            onChange={(e) => handleInputChange('pinHeaderRows', e.target.checked ? 1 : 0)}
                            style={{ marginRight: '10px' }}
                        />
                        <Form.Label>Pin header row(s)</Form.Label>
                        <Form.Control
                            type="number"
                            value={tableProperties.pinHeaderRows}
                            onChange={(e) => handleInputChange('pinHeaderRows', e.target.value)}
                            style={{ width: '80px', marginLeft: 'auto' }}
                        />
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label style={{ fontSize: 15 }}>Cell Padding</Form.Label>
                        <Form.Control
                            type="number"
                            value={tableProperties.cellPadding}
                            onChange={(e) => handleInputChange('cellPadding', e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label style={{ fontSize: 15 }}>Border Width</Form.Label>
                        <Form.Control
                            type="number"
                            value={tableProperties.borderWidth}
                            onChange={(e) => handleInputChange('borderWidth', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label style={{ fontSize: 15 }}>Border Color</Form.Label>
                        <Form.Control
                            type="color"
                            value={tableProperties.borderColor}
                            onChange={(e) => handleInputChange('borderColor', e.target.value)}
                        />
                    </Form.Group>

                    <Form.Label style={{ fontSize: 15 }}>Cell Background Color</Form.Label>
                    <Form.Group className="mb-2">
                        <Form.Control
                            type="color"
                            value={
                                selectedCell
                                    ? tableProperties.cellBackgroundColor
                                    : '#ffffff'
                            }
                            onChange={(e) => handleCellBackgroundColorChange(e.target.value)}
                            disabled={!selectedCell}
                        />
                    </Form.Group>

                    <Form.Group className="mb-2">
                        <Form.Label style={{ fontSize: 15 }}>Cell Alignment</Form.Label>
                        <Form.Select
                            value={tableProperties.cellAlignment}
                            onChange={(e) => handleInputChange('cellAlignment', e.target.value)}>
                            <option value="TopLeft">Top Left</option>
                            <option value="TopCenter">Top Center</option>
                            <option value="TopRight">Top Right</option>
                            <option value="MiddleLeft">Middle Left</option>
                            <option value="MiddleCenter">Middle Center</option>
                            <option value="MiddleRight">Middle Right</option>
                            <option value="BottomLeft">Bottom Left</option>
                            <option value="BottomCenter">Bottom Center</option>
                            <option value="BottomRight">Bottom Right</option>
                        </Form.Select>
                    </Form.Group>

                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default TableProperties;
