import React, { useEffect, useRef, useState } from 'react';
import { BsTabletLandscape } from 'react-icons/bs';
import { VscCopy } from 'react-icons/vsc';

const LayoutOrientationDesign = ({ setOrientation }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    return (
        <>
            <div ref={dropdownRef}>
                <div onClick={toggleOpen} className="position-relative">
                    Orientation
                </div>
                {isOpen && (
                    <div className="margin-dropdown position-absolute" style={{ width: 150 }}>
                        <div
                            className="margin-option border-bottom d-flex gap-2 CP"
                            onClick={() => {
                                setOrientation('portrait');
                                setIsOpen(false);
                            }}
                        >
                            <div style={{ fontSize: 40, marginTop: -18 }}><VscCopy /></div>
                            <div style={{ marginTop: 10 }}>Portrait</div>
                        </div>
                        <div
                            className="margin-option border-bottom d-flex gap-2 CP"
                            onClick={() => {
                                setOrientation('landscape');
                                setIsOpen(false);
                            }}
                        >
                            <div style={{ fontSize: 40, marginTop: -10 }}><BsTabletLandscape /></div>
                            <div style={{ marginTop: 10 }}>Landscape</div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default LayoutOrientationDesign;
