import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

const InsertCustomTable = ({ handleClose, show, handleTableSelection }) => {
    const [customRows, setCustomRows] = useState(1);
    const [customColumns, setCustomColumns] = useState(1);

    const handleInsertClick = () => {
        handleTableSelection(customRows, customColumns);
        handleClose();
    };

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Insert Custom Table</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-1">
                        <Row>
                            <Col md={6} className='text-end'>
                                <Form.Label>Number of Columns :</Form.Label>
                            </Col>
                            <Col md={6}>
                                <Form.Control
                                    type="number"
                                    value={customColumns}
                                    onChange={(e) => setCustomColumns(Number(e.target.value))}
                                    min="1"
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Row>
                            <Col md={6} className='text-end'>
                                <Form.Label>Number of Rows :</Form.Label>
                            </Col>
                            <Col md={6}>
                                <Form.Control
                                    type="number"
                                    value={customRows}
                                    onChange={(e) => setCustomRows(Number(e.target.value))}
                                    min="1"
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className='toolbar_button'>
                    <button onClick={handleInsertClick}>
                        Insert
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default InsertCustomTable;
